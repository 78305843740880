<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('financial_years.create')" to="/accounting/financial-years/create"  class="btn btn-primary font-weight-bolder"><v-icon>mdi-plus</v-icon> {{ $t('financial_years.new_financial_year') }}</router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('financial_years.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('financial_years.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('Added_By')}}
                            </label>
                            <!-- <select name="" id="user_id" v-model="filters.user_id" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in users" :value="row.id" :key="index">{{ row.name }}</option>
                            </select> -->
                            <multiselect v-model="user"
                                :placeholder="$t('Added_By')"
                                label="name"
                                track-by="id"
                                :options="users"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getUsers($event)">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div>
                    <div class="row justify-content-end p-4">
                        <custom-export-data v-if="$can('financial_years.export_data')" :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.financial_years')"></custom-export-data>
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('financial_years.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.status)" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox v-else size="lg" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2"  v-if="$can('financial_years.update')" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('financial_years.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                        <v-btn v-b-tooltip.hover :title="$t('view')" icon color="pink" :to="`/accounting/financial-years/view/${props.row.id}`">
                            <v-icon small class="mr-2 text-info">mdi-eye</v-icon>
                        </v-btn>
                        <br>
                        <!-- && statusError == false -->
                        <div v-if="props.row.is_tree_active == true">
                            <b-dropdown  id="dropdown-offset" :text="$t('more_actions')" variant="outline-primary" class="m-2">
                                <!-- to="/accounting/financial-years/create" -->
                                <!-- <router-link class="dropdown-item" 
                                    :to="{name: 'financial-years.create', query: {is_clone: true}}">
                                    {{ $t('MENU.clone_tree_chart_with_basic_info') }}
                                </router-link> -->
                                <button v-if="$can('financial_years.create_simple_tree')" @click="createTreeChart(2)" class="dropdown-item">
                                    {{$t('MENU.create_simple_tree_chart_with_basic_info')}}
                                </button>
                                <button v-if="$can('financial_years.create_complex_tree')" @click="createTreeChart(1)" class="dropdown-item">
                                    {{$t('MENU.create_complex_tree_chart_with_basic_info')}}
                                </button>
                            </b-dropdown>
                        </div>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
        <!-- <b-modal ref="status-modal" size="lg" hide-footer :title="$t('MENU.create_tree_chart_with_basic_info')">
            <create-tree-chart-form @hide-modal="hideModal()"
                :status-list="status_list"
                :url="routeCreateTreeChart"
                :current-id="prefix"
                :current-status="digits"
                @handling-data="getDataAfterCreateChart">
            </create-tree-chart-form>
        </b-modal> -->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    // import CreateTreeChartForm from "@/view/content/forms/CreateTreeChartForm";

    export default {
        name: "index-financial-years",
        // components: {'create-tree-chart-form': CreateTreeChartForm,},
        data() {
            return {
                mainRoute: 'accounting/financial-years',
                subMainRoute: 'accounting/financial-year',
                mainRouteDependency: 'base/dependency',
                routeCreateTreeChart: 'accounting/financial-year/create-tree-chart',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    parent_category: null,
                    name: '',
                    from_date: '',
                    to_date: '',
                    status: '',
                    user_id : '',
                },

                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                users: [],
                user : '',
                prefix: null,
                digits: null,
                columns: ['start_date', 'end_date', 'added_by', 'created_at', 'status', 'actions'],
            }
        },
        watch: {
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('financial_years.start_date')] = 'start_date';
                fields[this.$t('financial_years.end_date')] = 'end_date';
                fields[this.$t('financial_years.added_by')] = 'added_by';
                fields[this.$t('financial_years.created_at')] = 'created_at';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        start_date: that.$t('financial_years.start_date'),
                        end_date: that.$t('financial_years.end_date'),
                        added_by: that.$t('financial_years.added_by'),
                        created_at: that.$t('financial_years.created_at'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.financial_years")}]);
            // this.getUsers();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.from_date = '';
                this.filters.to_date = '';
                this.filters.status = '';
                this.filters.user_id = '';
                this.user = null;

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'financial-years.edit', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            getUsers(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/users`,{params:{filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
                }else{
                    this.users = [];
                }
            },

            createTreeChart(status) {
                /**
                 * simple => 2, complex => 1
                 */
                ApiService.patch(`${this.routeCreateTreeChart}/${status}`)
                .then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
            showModal() {
                this.$refs['status-modal'].show()
            },
            hideModal() {
                this.$refs['status-modal'].hide();
            },
            getDataAfterCreateChart() {
                this.prefix = null;
                this.digits = null;
                this.doFilter();
            },

            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    status: (status ? '1' : '0'),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$refs.table.refresh();
                    this.$errorAlert(error);
                });
            },

        },
    };
</script>
